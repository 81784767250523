import { API_DATE_FORMAT } from './formats';
import { getDateUtilsInternalLibraryInstance } from './internalLibrary/instance';
import { DateUtilsInternalLibraryObject } from './internalLibrary/libraryTypes';

export class PeriodDateUtils {
  static getDayStringArrayFromPeriod(
    from: string,
    to: string,
    missLastDay: boolean = false
  ): string[] {
    const firstItemDay = getDateUtilsInternalLibraryInstance()(from).add(
      -1,
      'days'
    );

    return Array(this.getDayCount(from, to) + (missLastDay ? 0 : 1))
      .fill(null)
      .map(() => firstItemDay.add(1, 'days').format(API_DATE_FORMAT));
  }

  static getDayStringArrayFromDatePeriod(
    from: Date,
    to: Date,
    missLastDay: boolean = false
  ): string[] {
    const firstItemDay = getDateUtilsInternalLibraryInstance()(from).add(
      -1,
      'days'
    );

    return Array(this.getDayCount(from, to) + (missLastDay ? 0 : 1))
      .fill(null)
      .map(() => firstItemDay.add(1, 'days').format(API_DATE_FORMAT));
  }

  static getDayCount(
    from: string | Date | DateUtilsInternalLibraryObject,
    to: string | Date | DateUtilsInternalLibraryObject,
    stringDateFormat?: string,
    options?: { useUTC?: boolean }
  ) {
    const opts: Required<typeof options> = {
      useUTC: false,
      ...(options || {}),
    };

    const DATE_STRING_FORMAT = 'YYYY-MM-DD';

    const getDateString = (date: Date): string => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so we need to add 1
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    };

    const startOfDay = (date: string, dateFormat?: string) =>
      (opts.useUTC
        ? getDateUtilsInternalLibraryInstance().utc
        : getDateUtilsInternalLibraryInstance())(date, dateFormat).startOf(
        'day'
      );

    const prepareOperand = (
      operand: string | Date | DateUtilsInternalLibraryObject
    ) => {
      if (typeof operand === 'string') {
        return startOfDay(operand, stringDateFormat);
      }

      if (operand instanceof Date) {
        return startOfDay(getDateString(operand), DATE_STRING_FORMAT);
      }

      return startOfDay(getDateString(operand.toDate()), DATE_STRING_FORMAT);
    };

    const toOperand = prepareOperand(to);
    const fromOperand = prepareOperand(from);

    return toOperand.diff(fromOperand, 'days');
  }

  static getDateParts(date: Date | string | DateUtilsInternalLibraryObject) {
    const momentDate = getDateUtilsInternalLibraryInstance()(date, 'MM/YYYY');

    return {
      day: momentDate.date(),
      month: momentDate.month(),
      year: momentDate.year(),
    };
  }

  static getDateFromWeek(
    week: number,
    year: number,
    day: string | number = 'Monday'
  ) {
    return getDateUtilsInternalLibraryInstance()()
      .day(day)
      .year(year)
      .week(week)
      .toDate();
  }

  static getISOWeekNumber(date: Date): number {
    const momentDate = getDateUtilsInternalLibraryInstance()(date);

    return momentDate.isoWeek();
  }
}
