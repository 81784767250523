import { createContext } from 'react';

import { ProfileCentersContext } from '@ac/library-api';

import { useDefinedContext } from '../../../store';

import { ProfileCentersBusinessContextStore } from './store';

export const ProfileCentersBusinessContext = createContext<
  ProfileCentersBusinessContextStore | undefined
>(undefined);

export const useProfileCentersBusinessContext =
  (): ProfileCentersBusinessContextStore =>
    useDefinedContext(ProfileCentersBusinessContext);

export const useProfileCentersDefinedBusinessContext =
  (): ProfileCentersContext => {
    const {
      state: { businessContext },
    } = useProfileCentersBusinessContext();

    if (!businessContext) {
      throw new Error(
        'The business context has not been loaded yet. Initialize it before usage.'
      );
    }

    return businessContext;
  };
